// Variable overrides
$body-bg: #ffffff;

$secondary: #106bef;
$primary: #0fa1ff;
$dark: #979797;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.4;
$h2-font-size: $font-size-base * 1.2;

$card-border-radius: 20px;
$card-cap-bg: none;

$dropdown-color: #0fa1ff;
$dropdown-bg: #ffffff;
$dropdown-border-color: #ffffff;
$caret-vertical-align: 0.3em * 0.5;
$caret-spacing: 0.5em;

$nav-link-color: #6c757d;
$nav-link-hover-color: #0fa1ff;
$nav-pills-border-radius: 2rem;

$progress-border-radius: 20px;

$nav-tabs-border-width: 0px;
$nav-tabs-link-active-color: #0fa1ff;
$nav-tabs-link-active-bg: none;

$input-border-radius: 20px;
$input-font-size: 14px;

$custom-select-border-radius: 20px;

$btn-font-size: 14px;
$btn-border-radius: 24px;
$btn-font-weight: 500;
$btn-padding-x: 0.9rem;
$btn-padding-y: 0.5rem;

$modal-content-border-radius: 12px;

$box-shadow: 0 0.3rem 1rem rgba(#000, 0.1);

$alert-border-radius: 1.2rem;

@import "~bootstrap/scss/bootstrap";
@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";

// Fonts import
@font-face {
  font-family: "DM Sans";
  src: url("../../assets/fonts/DMSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("../../assets/fonts/DMSans-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("../../assets/fonts/DMSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: "DM Sans";
}

.btn:disabled {
  background-color: #777676;
  border: 1px solid #777676;
  color: white;
}

a.disabled, p.disabled {
  color: #777676;
  cursor: default;

  &:hover {
    text-decoration: none;
  }
}

.navbar {
  height: 5rem;

  #main-title {
    font-size: 1.2rem;
  }

  .nav-pills .nav-link#login {
    background: $secondary;
    border-color: $secondary;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tooltip::after {
  display: none !important;
}

// Tables
table.fixed {
  table-layout: fixed;
}

table.disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
}

table.fixed td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Detail list
ul.detail-list {
  list-style: none;
  padding-left: 1em;
}

.disabled.card {
  background-color: #f2f2f2;
  color: #777676;
}

/*** Notifications ***/
/*** Example rules ***/

.redux-toastr .bottom-center {
  max-width: 720px;
  width: fit-content;
  margin-bottom: 60px;
}

.redux-toastr .toastr {
  min-height: 52px;
  border-radius: 50px;
  min-height: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  width: inherit;

  .rrt-left-container {
    width: 56px;
    min-height: 52px;
    display: flex;
    align-items: center;

    .rrt-holder {
      width: 56px;
      min-height: 52px;
      display: flex;
      align-items: center;
      padding-left: 22px;
      padding-right: 12px;
      position: initial;
      margin-top: 0;
    }
  }

  .rrt-title,
  .rrt-right-container {
    display: none;
    width: 0%;
  }

  .rrt-middle-container {
    min-height: 52px;
    display: flex;
    align-items: center;
    width: max-content;
    max-width: 650px;
    margin-left: 56px;
    padding: 16px 20px 16px 0px;
  }

  .rrt-text {
    font-size: 14px;
    line-height: 18px;
    color: #434343;
  }

  &.rrt-info {
    background-color: #EDF5FF;
    border: 1px solid #A7C5FD;
  }

  &.rrt-success {
    background-color: #EDFFF8;
    border: 1px solid #B1E4BF;
  }

  &.rrt-warning {
    background-color: #FFFAED;
    border: 1px solid #FCD28C;
  }

  &.rrt-error {
    background-color: #FFEDED;
    border: 1px solid #FFA7A0;
  }

  // & button {}
  // .close-toastr {}
}

// Reset defaults
ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Custom scrollbar
::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-track {
  background-color: #D4D7DB ;
  width: 0.75rem;
  border-radius: 22px;
}

::-webkit-scrollbar-thumb {
  background-color: #97A2B6;
  border-radius: 22px;
}

body::-webkit-scrollbar-track {
  margin: 120px 0 40px 0;
}

// Sidebar links
.inactive-sidebar-link {
  color: #6c757d;
  font-weight: 500;
  padding: 0.75rem 3rem;

  &:hover {
    color: #0f56b3;
  }
}

.active-sidebar-link {
  color: #0f56b3;
  background-color: #edf5ff;

  img {
    color: #0f56b3;
  }
}

.popover {
  max-width: 450px !important;
  width: 100%;
  left: -5.75% !important;
  border-radius: 6px;
}

.popover .arrow::after,
.popover .arrow::before {
  left: 150px;
}


.users-filters-popover-enter {
  opacity: 0;
  transform: scale(0.9);
}

.users-filters-popover-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms, transform 400ms;
}

.users-filters-popover-exit {
  opacity: 1;
}

.users-filters-popover-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 400ms, transform 400ms;
}

.font {
  &-xxs {
    font-size: 0.625rem;
  }

  &-xs {
    font-size: 0.75rem; // 12px
  }

  &-sm {
    font-size: 0.875rem; // 14px
  }

  &-md {
    font-size: 1rem; //16px
  }

  &-lg {
    font-size: 1.125rem; // 18px
  }

  &-xl {
    font-size: 1.25rem; // 20px
  }

  &-xl2 {
    font-size: 1.375rem; // 22px
  }

  &-xl3 {
    font-size: 1.5rem; // 24px
  }
}

.fontWeight {
  &-normal {
    font-weight: 400;
  }

  &-medium {
    font-weight: 500;
  }

  &-bold {
    font-weight: 700;
  }
}

.mx {
  &-12 {
    margin: 12px 0;
  }
}

.w-15 {
  width: 15px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.black {
  color: #000;
}

.grey {
  color: #4f4f4f;
}

//Forms
.custom-select:disabled {
  opacity: 1;
}

.rjsf {
  max-width: 873px;
}

.rjsf > .form-group {
  margin: 3rem 0;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 20px;
  padding: 34px;
}

.rjsf > .form-group h5 {
  margin-bottom: 1.25rem;
  font-size: 16px;
  font-weight: 500;
}

.rjsf > .form-group hr {
  display: none;
}

.rjsf .row {
  margin-bottom: 0 !important;
}

//ProgresBar
.progress-bar {
  border-radius: 20px;
}

.invalid-feedback {
  font-size: 12px;
}

.tooltip-inner {
  max-width: none;
}

.tooltip::after {
  //tooltip arrow on left side
  position: absolute;
  left: 25px;
  bottom: 6%;
  content: '';
  width: 8px;
  height: 10px;
  background: black;
  transform: rotate(45deg);
}

.tooltip-normal > .tooltip::after {
  display: none;
}

// Service parameters

form.serviceParameters > div {

  margin-bottom: 0;

  fieldset {
    margin-left: 1rem;
    display: grid;
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;

  }
}

//Fix background color when error in form input

.list-group > .list-group-item {
  background-color: #f8f9fa;
}

.MuiAvatarGroup-root > .MuiAvatar-root{
  background-color: rgba(16, 107, 239, 1);
}